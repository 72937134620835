import {
  CI_STATUS_LEGAL_RESIDENT,
  TAX_FILING_STATUS_DEPENDENT,
  TAX_FILING_STATUS_FILING_JOINTLY,
  WHO_SUPPORT_WITHOUT_INCOME_OTHER,
  WHO_SUPPORT_WITHOUT_INCOME_SELF,
} from 'components/EditPersonForm/schema'
import {getExtendedName} from './name'

export const isValidDueDate = (date: any) => {
  const today = new Date()
  const yearDiff = date.getFullYear() - today.getFullYear()
  const monthDiff = date.getMonth() - today.getMonth()
  const overallDiffInMonths = yearDiff * 12 + monthDiff
  // console.log({overallDiffInMonths})
  return overallDiffInMonths >= 0 && overallDiffInMonths <= 9
}

const isValidName = (name: string) => {
  const nameRegex = /^[a-zA-Z ]*$/
  console.log({name, isValid: nameRegex.test(name)})
  return nameRegex.test(name) && !verifySpaces(name)
}

function verifySpaces(name: string) {
  const excessiveSpacesRegex = /\s{2,}/ // Matches two or more consecutive spaces
  // const surroundingSpacesRegex = /^\s+|\s+$/ // Matches spaces at the beginning or end of the sentence

  const hasExcessiveSpaces = excessiveSpacesRegex.test(name.trim())
  // const hasSurroundingSpaces = surroundingSpacesRegex.test(name)

  return hasExcessiveSpaces //|| hasSurroundingSpaces
}

export const isValidInitialDateOfService = (initialDateOfService: any) => {
  const oneHour = 60 * 60 * 1000

  const diffHours: number = Math.round(
    (Date.now() - new Date(initialDateOfService).getTime()) / oneHour
  )
  // console.log({diffHours})
  return diffHours >= 0 && diffHours <= 144
}

export const getFullName = (person: any) =>
  `${person.firstName} ${
    person.middleName ? person.middleName.charAt(0).toUpperCase() : ''
  }${person.middleName ? ' ' : ''}${person.lastName}`

export const getHeadOfHouseholdParentOrLegalGuardian = (
  members: any[],
  application: any
) => {
  const relationships = application.person.relationships

  const memberPerson = members.map((member) => member.person)

  const femaleMembers = memberPerson
    .filter((member) => member.gender === 'Female')
    .map((member) => member.id)

  const femaleParentOrLegalGuardian = relationships.find(
    (relation: any) =>
      femaleMembers.indexOf(relation.otherPerson.id) >= 0 &&
      relation.relationshipType === 'Parent/legal guardian'
  )

  const maleMembers = memberPerson
    .filter((member) => member.gender === 'Male')
    .map((member) => member.id)

  const maleParentOrLegalGuardian = relationships.find(
    (relation: any) =>
      maleMembers.indexOf(relation.otherPerson.id) >= 0 &&
      relation.relationshipType === 'Parent/legal guardian'
  )
  return femaleParentOrLegalGuardian || maleParentOrLegalGuardian || null
}

export const getHouseholdMembersFromRelationships = (
  patient: any,
  application: any
) => {
  const _householdMembers: any = [
    {
      ...patient,
      person: {
        ...patient.person,
        initialDateOfService: application.initialDateOfService,
        isPatient: true,
        wantsPresumptiveEligibility: true,
        wantsNJFC: true,
        ciDocumentName:
          patient.person.ciDocumentName || getFullName(patient.person),
        taxFilerOfDependent: null,
        relationshipWithTaxFiler: null,
        filingJointly: null,
      },
    },
  ]

  application.person.relationships.forEach((relationship: any) => {
    const matchingRelationshipIndex = application.householdMembers.findIndex(
      (member: any) => relationship.otherPerson.id === member.person.id
    )
    const matchingHouseholdMember =
      application.householdMembers[matchingRelationshipIndex]

    if (matchingRelationshipIndex >= 0) {
      _householdMembers.push({
        id: matchingHouseholdMember.id,
        person: {
          ...relationship['otherPerson'],
          initialDateOfService: '',
          isPatient: false,
          wantsPresumptiveEligibility: false,
          wantsNJFC: relationship['otherPerson'].wantsCoverage ?? false,
          ciDocumentName:
            relationship['otherPerson'].ciDocumentName ||
            getFullName(relationship['otherPerson']),
          taxFilerOfDependent: null,
          relationshipWithTaxFiler: null,
          filingJointly: null,
        },
        createdAt: matchingHouseholdMember.createdAt,
        __typename: matchingHouseholdMember.__typename,
      })
    }
  })
  return _householdMembers
}
export const getAllRelationships = (patient: any, application: any) => {
  const _householdMembers: any = [
    {
      ...patient,
      person: {
        ...patient.person,
        initialDateOfService: application.initialDateOfService,
        isPatient: true,
        wantsPresumptiveEligibility: true,
        wantsNJFC: true,
        ciDocumentName:
          patient.person.ciDocumentName || getFullName(patient.person),
        taxFilerOfDependent: null,
        relationshipWithTaxFiler: null,
        filingJointly: null,
      },
    },
  ]

  application.person.relationships.forEach((relationship: any) => {
    const matchingRelationshipIndex = application.householdMembers.findIndex(
      (member: any) => relationship.otherPerson.id === member.person.id
    )
    const matchingHouseholdMember =
      application.householdMembers[matchingRelationshipIndex]

    if (matchingRelationshipIndex >= 0) {
      _householdMembers.push({
        id: matchingHouseholdMember.id,
        person: {
          ...relationship['otherPerson'],
          initialDateOfService: '',
          isPatient: false,
          wantsPresumptiveEligibility: false,
          wantsNJFC: relationship['otherPerson'].wantsCoverage ?? false,
          ciDocumentName:
            relationship['otherPerson'].ciDocumentName ||
            getFullName(relationship['otherPerson']),
          taxFilerOfDependent: null,
          relationshipWithTaxFiler: null,
          filingJointly: null,
        },
        createdAt: matchingHouseholdMember.createdAt,
        __typename: matchingHouseholdMember.__typename,
      })
    } else {
      _householdMembers.push({
        id: '1423',
        person: {
          ...relationship['otherPerson'],
          initialDateOfService: '',
          isPatient: false,
          wantsPresumptiveEligibility: false,
          wantsNJFC: relationship['otherPerson'].wantsCoverage ?? false,
          ciDocumentName:
            relationship['otherPerson'].ciDocumentName ||
            getFullName(relationship['otherPerson']),
          taxFilerOfDependent: null,
          relationshipWithTaxFiler: null,
          filingJointly: null,
        },
        createdAt: '2024-08-12T02:57:00Z',
        __typename: 'ApplicationHouseholdMember',
      })
    }
  })
  return _householdMembers
}

const requiredFields = {
  person: {
    id: null,
    homeAddressStreet: null,
    homeAddressCity: null,
    homeAddressZip: null,
    preferredLanguage: null,
    mailingAddressStreet: null,
    mailingAddressCity: null,
    mailingAddressState: null,
    mailingAddressZip: null,

    firstName: null,
    middleName: null,
    lastName: null,
    dob: null,
    maritalStatus: null,
    gender: null,
    pregnant: null,
    expectedChild: null,
    dueDate: null,
    relationships: [
      {
        id: null,
        relationshipType: null,
        reverseRelationshipType: null,
        otherPerson: {
          id: null,
        },
      },
    ],
    initialDateOfService: null,
    dateOfEntry: null,
    uscisNo: null,

    incomeSources: [
      {
        id: null,
        incomeType: null,
        employerName: null,
        fullPartTime: null,
        employerAddressStreet: null,
        employerAddressCity: null,
        employerAddressState: null,
        employerAddressZip: null,
        employerPhone: null,
        startMonth: null,
        startYear: null,
        payFrequency: null,
        amount: null,
      },
    ],
    taxFilingStatus: null,
    taxFilerOfDependent: null,
    relationshipWithTaxFiler: null,
    additionalComments: null,
    whoSupportWithoutIncome: null,
    selfSupportWithoutIncome: null,
    othersSupportWithoutIncome: null,
  },
  householdMembers: [
    {
      id: null,
      person: {
        id: null,
        firstName: null,
        middleName: null,
        lastName: null,
        dob: null,
        maritalStatus: null,
        gender: null,
        pregnant: null,
        expectedChild: null,
        dueDate: null,

        relationships: [
          {
            id: null,
            relationshipType: null,
            reverseRelationshipType: null,
            otherPerson: {
              id: null,
            },
          },
        ],

        initialDateOfService: null,
        dateOfEntry: null,
        uscisNo: null,

        incomeSources: [
          {
            id: null,
            incomeType: null,
            amount: null,
            employerName: null,
            fullPartTime: null,
            employerAddressStreet: null,
            employerAddressCity: null,
            employerAddressState: null,
            employerAddressZip: null,
            employerPhone: null,
            startMonth: null,
            startYear: null,
            payFrequency: null,
          },
        ],
        taxFilingStatus: null,
        taxFilerOfDependent: null,
        relationshipWithTaxFiler: null,
        whoSupportWithoutIncome: null,
        selfSupportWithoutIncome: null,
        othersSupportWithoutIncome: null,
      },
    },
  ],
  primaryPointOfContact: {
    firstName: null,
    middleName: null,
    lastName: null,
  },
  missingSpouseDetails: null,
  allRelationships: [
    {
      person: {
        id: null,
        firstName: null,
        middleName: null,
        lastName: null,
        dob: null,
        maritalStatus: null,
        gender: null,
        pregnant: null,
        expectedChild: null,
        dueDate: null,
      },
    },
  ],
}

export const overrideFields = (
  obj: any,
  validate: boolean = true,
  mappedData: any
) => {
  let eligibleForSubmission = true
  function transform(
    key: string,
    parentObj: any,
    obj: any,
    requiredObj: any
  ): any {
    if (
      obj === null ||
      (typeof obj === 'object' &&
        Object.prototype.toString.call(obj) === '[object Date]') ||
      typeof obj !== 'object'
    ) {
      const isRequired =
        validate &&
        requiredObj !== undefined &&
        typeof obj !== 'boolean' &&
        getAdditionalConditionsChecked(key, parentObj)

      const isValid = isRequired
        ? getAdditionalValidationChecked(obj, key, parentObj, mappedData)
        : true
      // console.log({key, isRequired, isValid})
      if (!isValid) {
        eligibleForSubmission = false
      }

      return {
        value: obj,
        required: isRequired,
        valid: isValid,
      }
    }

    // Recursive case: obj is an array or object
    if (Array.isArray(obj)) {
      return obj.map((item: any) =>
        transform(key, item, item, requiredObj ? requiredObj[0] : undefined)
      )
    }

    const transformedObj: any = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Transform each field using the corresponding requiredObj key
        transformedObj[key] = transform(
          key,
          obj,
          obj[key],
          requiredObj ? requiredObj[key] : undefined
        )
      }
    }
    return transformedObj
  }

  return {
    modifiedApplication: transform('', obj, obj, requiredFields),
    eligibleForSubmission,
  }
}
const getAdditionalValidationChecked = (
  val: any,
  key: string,
  obj: any,
  mappedData: any
) => {
  const {_householdMembers} = mappedData
  switch (key) {
    case 'firstName':
      return isValidName(obj['firstName'])
    case 'middleName':
      return (
        !obj['middleName'] ||
        (obj['middleName'] && isValidName(obj['middleName']))
      )
    case 'lastName':
      return isValidName(obj['lastName'])
    case 'dueDate':
      return isValidDueDate(obj['dueDate'])
    case 'initialDateOfService':
      return isValidInitialDateOfService(obj['initialDateOfService'])
    case 'uscisNo':
      return obj['uscisNo']?.length > 7 && obj['uscisNo']?.length < 10
    case 'missingSpouseDetails':
      const marriedMemberCount = _householdMembers.filter(
        (member: any) =>
          member?.person?.maritalStatus?.toLowerCase() === 'married'
      ).length
      return marriedMemberCount % 2 === 0
    case 'taxFilingStatus':
      const _joinFilerCount = _householdMembers.filter(
        (member: any) =>
          member?.person?.taxFilingStatus === TAX_FILING_STATUS_FILING_JOINTLY
      ).length
      if (obj['taxFilingStatus'] === TAX_FILING_STATUS_FILING_JOINTLY) {
        return _joinFilerCount % 2 === 0
      }
      return true
    case 'whoSupportWithoutIncome':
      const withIncome =
        _householdMembers.filter(
          (member: any) => member.person?.['incomeSources']?.length > 0
        ).length > 0
      const withSupportDetails =
        _householdMembers.filter(
          (member: any) => member.person?.['whoSupportWithoutIncome']
        ).length > 0
      if (
        withIncome ||
        (!withIncome &&
          obj['taxFilingStatus'] === TAX_FILING_STATUS_DEPENDENT) ||
        withSupportDetails
      ) {
        return true
      }
      return false
    case 'selfSupportWithoutIncome':
      const withIncome2 =
        _householdMembers.filter(
          (member: any) => member.person?.['incomeSources']?.length > 0
        ).length > 0
      const withSupportDetails2 =
        _householdMembers.filter(
          (member: any) =>
            member.person?.['whoSupportWithoutIncome'] &&
            (member.person?.['whoSupportWithoutIncome'] !==
              WHO_SUPPORT_WITHOUT_INCOME_SELF ||
              (member.person?.['whoSupportWithoutIncome'] ===
                WHO_SUPPORT_WITHOUT_INCOME_SELF &&
                member.person?.['selfSupportWithoutIncome']))
        ).length > 0

      console.log({
        member: obj['firstName'],
        withIncome2,
        taxDept:
          !withIncome2 &&
          obj['taxFilingStatus'] === TAX_FILING_STATUS_DEPENDENT,
        withSupportDetails2,
      })
      if (
        withIncome2 ||
        (!withIncome2 &&
          obj['taxFilingStatus'] === TAX_FILING_STATUS_DEPENDENT) ||
        withSupportDetails2
      ) {
        return true
      }
      return false
    case 'othersSupportWithoutIncome':
      const withIncome3 =
        _householdMembers.filter(
          (member: any) => member.person?.['incomeSources']?.length > 0
        ).length > 0
      const withSupportDetails3 =
        _householdMembers.filter(
          (member: any) =>
            member.person?.['whoSupportWithoutIncome'] &&
            (member.person?.['whoSupportWithoutIncome'] !==
              WHO_SUPPORT_WITHOUT_INCOME_OTHER ||
              (member.person?.['whoSupportWithoutIncome'] ===
                WHO_SUPPORT_WITHOUT_INCOME_OTHER &&
                member.person?.['othersSupportWithoutIncome']))
        ).length > 0
      console.log({
        member: obj['firstName'],
        withIncome3,
        taxDept:
          !withIncome3 &&
          obj['taxFilingStatus'] === TAX_FILING_STATUS_DEPENDENT,
        withSupportDetails3,
      })
      if (
        withIncome3 ||
        (!withIncome3 &&
          obj['taxFilingStatus'] === TAX_FILING_STATUS_DEPENDENT) ||
        withSupportDetails3
      ) {
        return true
      }
      return false

    case 'additionalComments':
      const householdMembersWithIncome = _householdMembers.filter(
        (member: any) => member.person?.['incomeSources']?.length > 0
      )
      const householdMembersWithSupportDetails = _householdMembers.filter(
        (member: any) =>
          member?.person?.whoSupportWithoutIncome &&
          ((member?.person?.whoSupportWithoutIncome ===
            WHO_SUPPORT_WITHOUT_INCOME_SELF &&
            member?.person?.selfSupportWithoutIncome) ||
            (member?.person?.whoSupportWithoutIncome ===
              WHO_SUPPORT_WITHOUT_INCOME_OTHER &&
              member?.person?.othersSupportWithoutIncome))
      )

      const isValidHouseholdMembersWithSupportDetails =
        householdMembersWithIncome.length > 0 ||
        (householdMembersWithIncome.length === 0 &&
          householdMembersWithSupportDetails.length > 0)

      return isValidHouseholdMembersWithSupportDetails
    default:
      return val !== null && val !== undefined && val !== ''
  }
}
const getAdditionalConditionsChecked = (key: string, obj: any) => {
  switch (key) {
    case 'mailingAddressStreet':
      return obj['mailingAddressCity'] ||
        obj['mailingAddressState'] ||
        obj['mailingAddressZip']
        ? true
        : false
    case 'mailingAddressCity':
      return obj['mailingAddressStreet'] ||
        obj['mailingAddressState'] ||
        obj['mailingAddressZip']
        ? true
        : false
    case 'mailingAddressState':
      return obj['mailingAddressStreet'] ||
        obj['mailingAddressCity'] ||
        obj['mailingAddressZip']
        ? true
        : false
    case 'mailingAddressZip':
      return obj['mailingAddressStreet'] ||
        obj['mailingAddressCity'] ||
        obj['mailingAddressState']
        ? true
        : false
    case 'pregnant':
      return obj['gender'] === 'Female'
    case 'expectedChild':
      return obj['gender'] === 'Female' && obj['pregnant']
    case 'dueDate':
      return obj['gender'] === 'Female' && obj['pregnant']
    case 'employerName':
    case 'fullPartTime':
    case 'startMonth':
    case 'startYear':
      return (
        obj['incomeType'] === 'Salary/Wages (has paystub)' ||
        obj['incomeType'] === 'Freelance/Paid Cash' ||
        obj['incomeType'] === 'Self employed/Business owner'
      )
    case 'employerAddressStreet':
    case 'employerAddressCity':
    case 'employerAddressState':
    case 'employerAddressZip':
    case 'employerPhone':
      return obj['employerInsurance']
    case 'dateOfEntry':
      return obj['ciStatus'] === CI_STATUS_LEGAL_RESIDENT
    case 'initialDateOfService':
      return obj['isPatient']
    case 'uscisNo':
      return obj['ciStatus'] === CI_STATUS_LEGAL_RESIDENT && obj['uscisNo']
    case 'taxFilerOfDependent':
    case 'relationshipWithTaxFiler':
      return (
        obj['taxFilingStatus'] &&
        obj['taxFilingStatus'] === TAX_FILING_STATUS_DEPENDENT &&
        !obj['dependentOfTaxFilerId']
      )
    default:
      return true
  }
}

export const getAdditionalComments = (
  t: any,
  householdMembersWithIncome: any,
  householdMembersWithSupportDetails: any
) => {
  let comment = ''
  if (householdMembersWithIncome && householdMembersWithIncome.length === 0) {
    if (
      householdMembersWithSupportDetails &&
      householdMembersWithSupportDetails.length > 0
    ) {
      let _comment = ''
      householdMembersWithSupportDetails.forEach(
        ({person: householdMember}: {person: any}) => {
          if (
            householdMember?.whoSupportWithoutIncome?.value &&
            householdMember?.whoSupportWithoutIncome?.value ===
              WHO_SUPPORT_WITHOUT_INCOME_SELF &&
            householdMember?.selfSupportWithoutIncome?.value
          ) {
            _comment = `${getExtendedName({
              ...{
                firstName: householdMember?.firstName.value,
                lastName: householdMember?.lastName.value,
                preferredName: '',
                middleName: householdMember?.middleName.value,
                suffix: '',
              },
              t,
            })} has no income in the last 30 days and is currently supporting him/herself. Explanation: ${
              householdMember?.selfSupportWithoutIncome?.value
            }.`
          }
          if (
            householdMember?.whoSupportWithoutIncome?.value &&
            householdMember?.whoSupportWithoutIncome?.value ===
              WHO_SUPPORT_WITHOUT_INCOME_OTHER &&
            householdMember?.othersSupportWithoutIncome?.value
          ) {
            _comment = `${getExtendedName({
              ...{
                firstName: householdMember?.firstName.value,
                lastName: householdMember?.lastName.value,
                preferredName: '',
                middleName: householdMember?.middleName.value,
                suffix: '',
              },
              t,
            })} has no income in last 30 days and is supported by 
              ${householdMember?.othersSupportWithoutIncome?.value} 
              ${
                householdMember?.supporterRelationship?.value
                  ? `(Relation: ${householdMember?.supporterRelationship?.value}).`
                  : null
              }`
          }
          console.log({_comment})
          let newComment = ''
          if (comment) {
            newComment = comment.concat(` `).concat(_comment)
          } else {
            newComment = comment.concat(_comment)
          }
          console.log({length: newComment.length})
          if (newComment.length <= 250) {
            // Allowed max length for additional comment is 250 on NJ Portal
            comment = newComment // If the comment does not exceed 250 characters, update the comment
          } else {
            return comment // If the comment exceeds 250 characters, return the current comment
          }
        }
      )
    } else {
      comment = 'Please add support information'
    }
  } else {
    comment = 'No comments'
  }
  return comment
}
